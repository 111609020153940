<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='esm m-auto'>
        <header-element class='mt-8'>{{ $t('pages.esims.d9a40dc9') }}</header-element>
        <skeleton-loader-component class='mt-24'
          :loading='!esims'>
          <div v-if='esims && esims.length'>
            <card-element class='nsa'
              v-for='esim in esims'
              :key='esim.id'>
              <div class='flex'>
                <plan-image-component class='gib overflow-hidden rounded'
                  :plan='esim.order.item.data'/>
                <div class='ml-24'>
                  <div class='text-xl'>{{ esim.order.item.data.name }}</div>
                  <div class='flex mt-16'>
                    <i class='icon text-gray-700'>signal_cellular_alt</i>
                    <div class='ml-16'>{{ esim.order.item.data.networkType }}</div>
                  </div>
                </div>
              </div>
              <div class='mt-24'>
                <template v-if='esim.plan.status === "ready"'>
                  <div class='flex items-center justify-between'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>wifi_tethering</i>
                      <div class='ml-16'>{{ $t('common.status') }}</div>
                    </div>
                    <badge-element class='whitespace-no-wrap bg-orange'>{{ $t('common.not-activated') }}</badge-element>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>sim_card</i>
                      <div class='ml-16'>{{ $t('common.serial-number') }}</div>
                    </div>
                    <div class='ml-24 truncate'>{{ esim.serialNumber }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>language</i>
                      <div class='ml-16'>{{ $t('common.data') }}</div>
                    </div>
                    <div v-if='esim.order.item.data.data.value > 0'>{{ esim.order.item.data.data.value }} {{ esim.order.item.data.data.unit }}</div>
                    <div v-else>{{ $t('common.unlimited') }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>event</i>
                      <div class='ml-16'>{{ $t('common.purchase-date') }}</div>
                    </div>
                    <div>{{ formatDate(esim.order.createdDate) }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>schedule</i>
                      <div class='ml-16'>{{ $t('common.install-before') }}</div>
                    </div>
                    <div>{{ formatDate(esim.expirationDate) }}</div>
                  </div>
                </template>
                <template v-if='esim.plan.status === "active"'>
                  <div class='flex items-center justify-between'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>wifi_tethering</i>
                      <div class='ml-16'>{{ $t('common.status') }}</div>
                    </div>
                    <badge-element class='whitespace-no-wrap'>{{ $t('common.active') }}</badge-element>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>sim_card</i>
                      <div class='ml-16'>{{ $t('common.serial-number') }}</div>
                    </div>
                    <div class='ml-24 truncate'>{{ esim.serialNumber }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>language</i>
                      <div class='ml-16'>{{ $t('common.remaining-data') }}</div>
                    </div>
                    <div v-if='esim.order.item.data.data.value > 0'>{{ esim.plan.remainingData.value }} / {{ esim.order.item.data.data.value }} {{ esim.order.item.data.data.unit }}</div>
                    <div v-else>{{ $t('common.unlimited') }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>event</i>
                      <div class='ml-16'>{{ $t('common.purchase-date') }}</div>
                    </div>
                    <div>{{ formatDate(esim.order.createdDate) }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>schedule</i>
                      <div class='ml-16'>{{ $t('common.validity') }}</div>
                    </div>
                    <div>{{ formatDistanceToNow(esim.plan.expirationDate) }} {{ $tc('common.day-left', formatDistanceToNow(esim.plan.expirationDate)) }}</div>
                  </div>
                </template>
                <template v-if='esim.plan.status === "expired"'>
                  <div class='flex items-center justify-between'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>wifi_tethering</i>
                      <div class='ml-16'>{{ $t('common.status') }}</div>
                    </div>
                    <badge-element class='whitespace-no-wrap bg-gray-500'>{{ $t('common.expired') }}</badge-element>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>sim_card</i>
                      <div class='ml-16'>{{ $t('common.serial-number') }}</div>
                    </div>
                    <div class='ml-24 truncate'>{{ esim.serialNumber }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>event</i>
                      <div class='ml-16'>{{ $t('common.purchase-date') }}</div>
                    </div>
                    <div>{{ formatDate(esim.order.createdDate) }}</div>
                  </div>
                  <div class='flex justify-between mt-24'>
                    <div class='flex'>
                      <i class='icon text-gray-700'>schedule</i>
                      <div class='ml-16'>{{ $t('common.expired-on') }}</div>
                    </div>
                    <div>{{ formatDate(esim.plan.expirationDate) }}</div>
                  </div>
                </template>
              </div>
              <router-link class='block mt-24'
                :to='{ name: "esim", params: { id: esim.id } }'>
                <button-element class='is-block is-outlined'>{{ $t('pages.esims.7b488afb') }}</button-element>
              </router-link>
            </card-element>
          </div>
          <div v-else>
            <div class='my-48 text-center text-gray-500'>{{ $t('pages.esims.8effe951') }}</div>
            <img class='ymk m-auto'
              src='images/3bbc.png'>
          </div>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import PlanImage from '@/components/plan-image'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import dateMixin from '@/mixins/date'
  import store from '@/store'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'plan-image-component': PlanImage,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    mixins: [dateMixin],

    computed: {
      esims () {
        return this.$store.state.me.esims
      }
    },

    mounted () {
      this.$store.dispatch('me/fetchEsims')
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .esm {
    max-width: 480px;
  }

  .nsa {
    &:not(:first-child) {
      @apply mt-16;
    }
  }

  .gib {
    height: 90px;
  }

  .ymk {
    width: 240px;
  }
</style>
